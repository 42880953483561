import React from 'react'
import PropTypes from 'prop-types'

import GlobalContextProvider from '../context/GlobalContextProvider'

const wrapRootProvider = ({ element }) => {
  return (
    <GlobalContextProvider>
      {element}
    </GlobalContextProvider>
  )
}

wrapRootProvider.propTypes = {
  element: PropTypes.node.isRequired
}

export default wrapRootProvider
