import React, { useEffect, useReducer, createContext } from 'react'
import PropTypes from 'prop-types'

import colors from '../styles/colors'

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const themes = Object.keys(colors)

const GlobalContextProvider = ({ children }) => {
  const initialState = {
    theme: 'blank'
  }

  useEffect(() => {
    dispatch({
      type: 'SET_THEME',
      theme: window.localStorage.getItem('theme') ? window.localStorage.getItem('theme') : 'light'
    })
  }, [])

  const reducer = (state, action) => {
    switch (action.type) {
      case 'TOGGLE_THEME': {
        let newTheme = themes[(themes.indexOf(state.theme) + 1) % themes.length]
        newTheme = newTheme === 'blank' ? 'light' : newTheme
        window.localStorage.setItem('theme', newTheme)
        return {
          ...state,
          theme: newTheme
        }
      }
      case 'SET_THEME': {
        return {
          theme: action.theme
        }
      }
      default:
        throw new Error('Incorrect action passed')
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default GlobalContextProvider
