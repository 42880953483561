import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { defaults, Bar } from 'react-chartjs-2'

export const BarChart = (props) => {
  defaults.global.defaultFontColor = 'grey'

  const [graphData, setGraphData] = useState(null)

  const skeletonData = {
    labels: [],
    datasets: [
      {
        label: 'Fetching Data...',
        data: [],
        backgroundColor: props.color
      }
    ]
  }

  useEffect(() => {
    const fetchGraphData = () => {
      fetch(props.url)
        .then((result) => result.json())
        .then((data) => {
          console.log(data)
          var labels = []
          var values = []
          for (const [key, value] of Object.entries(data[props.resultKey])) {
            labels.push(key)
            values.push(value)
          }
          const newGraphData = {
            labels: labels,
            datasets: [
              {
                label: props.label,
                data: values,
                backgroundColor: props.color
              }
            ]
          }
          setGraphData(newGraphData)
        })
    }
    fetchGraphData()
  }, [])

  if (!graphData) {
    return <Bar data={skeletonData}/>
  }
  return <Bar data={graphData}/>
}

BarChart.propTypes = {
  url: PropTypes.string,
  resultKey: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string
}

export default BarChart
