const colors = {
  blank: {
    subtext: '...',
    text: 'black',
    background: 'black',
    accent: 'black',
    meta: 'black',
    border: 'black',
    shadow: 'black',
    heart: 'black'
  },
  light: {
    subtext: 'Software Engineer',
    text: 'black',
    background: '#FFFFFF',
    accent: '#FF1B1C',
    meta: 'grey',
    border: 'rgb(242, 242, 242)',
    shadow: 'rgba(0, 0, 0, 0.05)',
    heart: '#E53935'
  },
  dark: {
    subtext: 'DevOps Engineer',
    text: '#E6E6E6',
    background: '#262833',
    accent: '#FD8E32',
    meta: 'grey',
    border: 'rgb(13, 13, 13)',
    shadow: 'rgba(255, 255, 255, 0.05)',
    heart: '#E53935'
  }
}

export default colors
